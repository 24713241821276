<template>
  <div style="display: contents">
    <a-button @click="deleteData" type="link">删除</a-button>
    <a-modal  v-model="visible" :title="tipsTitle" @ok="handleOk">
      {{tipsContent}}
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ["record",'tipsTitle','tipsContent'],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    deleteData() {
      this.visible = true;
    },
    handleOk() {
      this.$emit("deleteBut", this.record);
      this.visible = false;
    },
  },
};
</script>