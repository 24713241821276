<style lang="scss" scoped>
.pageDiv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
 ::v-deep .ant-btn-link{
  padding:0;
}
</style>

<template>
  <div class="consultingManagement">
    <a-spin size="large" :spinning="spinning">
      <a-card title="企业用户列表">
        <a-form class="formModel" layout="inline">
          <a-form-item label="企业/团队名称：" class="d-flex">
            <a-input v-model.trim="searchFrom.enterprise_name" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="姓名：" class="d-flex">
            <a-input v-model.trim="searchFrom.staff_name" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="手机号：" class="d-flex">
            <a-input v-model.trim="searchFrom.staff_phone" placeholder="请输入" />
          </a-form-item>
          <a-form-item class="d-flex">
            <a-button type="primary" style="margin-right: 10px;" @click="searchList" class="m-20">
              <a-icon type="search" />
              查询
            </a-button>
            <a-button class="m-10" @click="resetForm"> 重置 </a-button>
          </a-form-item>
        </a-form>
        <div>
          <a-button type="primary" @click="add" style="margin-top: 15px;margin-right: 10px;">
            <a-icon type="plus" />
            添加
          </a-button>
          <a-button v-if="batchDeleteView" type="primary" @click="del" style="margin-top: 15px">
            批量删除员工
          </a-button>
        </div>
        <div style="margin-top: 10px">
          <a-table :rowKey="(record, index) => record.menuId" :columns="table.columns" :data-source="data"
            :pagination="pagination" @change="pageChange" :scroll="{ x: 1500, y: height }" bordered>
            <span slot="action" slot-scope="text, record">
              <a-button style="padding: 0 5px 0 0;" type="link" @click="look(text)">查看</a-button>
              <a-button style="padding: 0 5px 0 0;" type="link" @click="edit(text)">编辑</a-button>
              <!-- <a-button type="link" @click="remove(text)">删除</a-button> -->
              <deletebut style="padding: 0 5px 0 0;" :tipsTitle="tipsTitle" :tipsContent="tipsContent"
                @deleteBut="remove" :record="record"></deletebut>
            </span>
          </a-table>
        </div>
        <!-- <a-modal v-model="visible" title="确认删除该用户信息?" @ok="handleOk">
      删除后将从该企业移出,请谨慎操作!</a-modal
    > -->
      </a-card>
    </a-spin>

  </div>
</template>

<script type="text/babel">
/**
 * TODO fileOverview
 * --------------
 * @author xiaoF
 * @since 2021/6/15.
 */
import * as api from "@/api/enterprise/enterprise.js";
import deletebut from "@/components/delete/index";
import { pagerules } from "@/utils/utils.js";
export default {
  name: "userList",
  components: {
    deletebut,
  },
  data() {
    return {
      tipsTitle: "确认删除该用户信息",
      tipsContent: "删除后将从该企业移出,请谨慎操作!",
      spinning: false,
      searchFrom: {
        enterprise_name: "",
        staff_name: "",
        staff_phone: "",
      },
      visible: false,
      show: false,
      data: [],
      table: {
        columns: [
          {
            title: "账号",
            dataIndex: "staff_phone", // TODO: 先显示手机号
            key: "id",
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "真实姓名",
            dataIndex: "staff_name",
            key: "staff_name",
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "手机号",
            dataIndex: "staff_phone",
            key: "staff_phone",
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "企业角色",
            dataIndex: "enterprise_role",
            key: "enterprise_role",
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "所属企业",
            dataIndex: "enterprise_name",
            key: "enterprise_name",
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: "操作",
            key: "action",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
            width: "170px",
            fixed: 'right',
          },
        ],
      },
      pagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total, range) => {
          return `共${total}条`;
        },
      },
      // 要删除的数据
      deleteData: {},
      // 角色
      enterpriseRoleArr: [],
      batchDeleteView: false,// 批量删除jian
      height:''
    };
  },
  // // 进入路由添加缓存
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.$store.dispatch("cachedRoutes/updateAliveRoutes", ["userList"]);
  //   });
  // },
  // // 离开清楚缓存
  // beforeRouteLeave(to, from, next) {
  //   if (to.name === "userlook") {
  //     this.$store.dispatch("cachedRoutes/updateAliveRoutes", ["userList"]);
  //   } else {
  //     this.$store.dispatch("cachedRoutes/updateAliveRoutes", []);
  //   }
  //   next();
  // },
  created() {
    this.initOptAuth()
  },
  methods: {
    // 判断批量删除按钮
    initOptAuth() {
      const magStorage = window.localStorage.getItem("IES_MAG");
      if (magStorage) {
        const magStorageJson = JSON.parse(magStorage)
        if (magStorageJson.user && magStorageJson.user.authorities && magStorageJson.user.authorities.length > 0) {
          this.batchDeleteView = magStorageJson.user.authorities.includes('batchDeleteAdmin')
        }
      }
    },
    // 查询
    searchList() {
      this.pagination.current = 1;
      this.getStaffList();
    },
    // 重置
    resetForm() {
      this.searchFrom = {
        enterprise_name: "",
        staff_name: "",
        staff_phone: "",
      };
      this.getStaffList();
    },
    pageChange(pageOption) {
      this.pagination.current = pageOption.current;
      this.pagination.pageSize = pageOption.pageSize;
      this.getStaffList();
    },
    handleChange() { },
    look(data) {
      this.$router.push({
        path: '/enterprise/userList/view',
        query: {
          staffId: data.id,
        },
      });
    },
    // 编辑
    edit(data) {
      this.$router.push({
        path: "/enterprise/userList/addStaff",
        query: {
          id: data.id,
          isEdit: true,
        },
      });
    },
    add() {
      this.$router.push({
        path: "/enterprise/userList/addStaff",
      })
    },
    del() {
      this.$router.push({
        path: "/enterprise/userList/delStaff",
      })
    },
    remove(data) {
      const that = this;
      that.deleteStaff(data);
    },
    /** 网络请求 */
    // 获取员工列表
    async getStaffList() {
      try {
        this.spinning = true;
        const result = await api.getStaffList(
          this.searchFrom,
          this.pagination.current,
          this.pagination.pageSize
        );
        this.spinning = false;
        if (result.data.data.staffInfoDTO) {
          this.data = result.data.data.staffInfoDTO;
          this.pagination.total = result.data.data.total;
        }
      } catch (error) {
        this.spinning = false;
      }
    },
    // 删除员工
    async deleteStaff(data) {
      try {
        this.spinning = true;
        const result = await api.deleteStaff(data);
        this.spinning = false;
        if (result.data === "删除成功") {
          this.pagination.current = pagerules(
            this.data.length,
            1,
            this.pagination.current
          );
          this.$message.success("删除成功");
          this.getStaffList();
        }
      } catch (error) {
        this.spinning = false;
      }
    },
  },
  mounted() {
    this.getStaffList();
    let handleResize = () => {
      this.height = window.innerHeight - 341 - 64 - 40 - 30
      let width = document.getElementsByClassName('consultingManagement')[0].clientWidth;
      if (width > 1890) {
        this.table.columns[this.table.columns.length - 1].fixed = '';
      } else {
        this.table.columns[this.table.columns.length - 1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
};
</script>
